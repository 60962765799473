<template>
  <div class="OpenAccount2">
    <div class="top">
      <span class="font">第1步 </span>
      <span class="font">第2步 </span>
      <span class="font">第3步 </span>
      <span class="font">第4步 </span>
    </div>
    <div class="title">
      <span>完善个人信息</span>
    </div>
    <div class="information">
      <div class="beneficiary">核对信息</div>
      <div>
        <div class="commonType">
          <span class="star">* </span>
          <span class="occupation"> 姓名</span>
        </div>
        <input v-model="queryParams.clientName" class="occupationType" type="text" name="fname"><br>
        <div class="commonType">
          <span class="star">* </span>
          <span class="occupation"> 性别</span>
        </div>
        <select class="occupationType" v-model="queryParams.clientGender">
          <option v-for="item in clientGenderTypes" :key="item" :label="item.clientGenderName"
                  :value="item.clientGender"></option>
        </select><br>
        <div class="commonType">
          <span class="star">* </span>
          <span class="occupation"> 年龄</span>
        </div>
        <input v-model="queryParams.clientAge" class="occupationType" type="text" name="fname"><br>
        <div class="commonType">
          <span class="star">* </span>
          <span class="occupation"> 证件类型</span>
        </div>
        <select class="occupationType" v-model="queryParams.idKindGb">
          <option v-for="item in idKindGbTypes" :key="item" :label="item.idKindGbName"
                  :value="item.idKindGb"></option>
        </select><br>
        <div class="commonType">
          <span class="star">* </span>
          <span class="occupation"> 证件号码</span>
        </div>
        <input v-model="queryParams.idNo" class="occupationType" type="text" name="fname"><br>
        <div class="commonType">
          <span class="star">* </span>
          <span class="occupation"> 证件有效期</span>
        </div>
        <input v-model="queryParams.idEnddate" class="occupationType" type="text" name="fname"><br>
        <div class="commonType">
          <span class="star">* </span>
          <span class="occupation"> 国籍</span>
        </div>
        <select class="occupationType" v-model="queryParams.fundNationality">
          <option v-for="item in fundNationalityTypes" :key="item" :label="item.fundNationalityName"
                  :value="item.fundNationality"></option>
        </select><br>
        <div class="commonType">
          <span class="star">* </span>
          <span class="occupation"> 所在地区</span>
        </div>
        <select class="occupationType">
          <option value="1">请选择</option>
        </select><br>
        <div class="commonType">
          <span class="star">* </span>
          <span class="occupation"> 详细地址</span>
        </div>
        <input v-model="queryParams.idAddress" class="occupationType" type="text" name="fname"><br>
      </div>
    </div>
    <form action="demo_form.php">
      <div class="commonType">
        <span class="bodyStar">* </span>
        <span class="bodyTitle"> 职业类别</span>
      </div>
      <div>
        <select class="bodyType" v-model="queryParams.ofundProfCode">
          <option v-for="item in occupationTypes" :key="item" :label="item.ofundProfName"
                  :value="item.ofundProfCode"></option>
        </select>
      </div>
      <div class="commonType">
        <span class="bodyStar">* </span>
        <span class="bodyTitle"> 年收入</span>
      </div>
      <div>
        <select class="bodyType" v-model="queryParams.income">
          <option v-for="item in incomeTypes" :key="item" :label="item.incomeName" :value="item.income"></option>
        </select>
      </div>
      <div class="commonType">
        <span class="bodyStar">* </span>
        <span class="bodyTitle"> 账户实际受益人</span>
      </div>
      <div>
        <select class="bodyType" v-model="queryParams.beneficiaryType">
          <option v-for="item in beneficiaryTypes" :key="item" :label="item.beneficiaryName"
                  :value="item.beneficiaryType"></option>
        </select>
      </div>
      <div class="body">
        <div class="beneficiary">实际收益人信息补齐</div>
        <div>
          <div class="commonType">
            <span class="star">* </span>
            <span class="occupation"> 姓名</span>
          </div>
          <input v-model="queryParams.beneficiary" class="occupationType" type="text" name="fname"><br>
          <div class="commonType">
            <span class="star">* </span>
            <span class="occupation"> 性别</span>
          </div>
          <select class="occupationType" v-model="queryParams.beneficiaryGender">
            <option v-for="item in clientGenderTypes" :key="item" :label="item.clientGenderName"
                    :value="item.clientGender"></option>
          </select><br>
          <div class="commonType">
            <span class="star">* </span>
            <span class="occupation"> 证件类型</span>
          </div>
          <select class="occupationType" v-model="queryParams.beneficiaryIdType">
            <option v-for="item in idKindGbTypes" :key="item" :label="item.idKindGbName"
                    :value="item.idKindGb"></option>
          </select><br>
          <div class="commonType">
            <span class="star">* </span>
            <span class="occupation"> 证件号码</span>
          </div>
          <input v-model="queryParams.beneficiaryIdno" class="occupationType" type="text" name="fname"><br>
          <div class="commonType">
            <span class="star">* </span>
            <span class="occupation"> 证件有效期</span>
          </div>
          <input v-model="queryParams.beneficiaryIdValidate" class="occupationType" type="text" name="fname"><br>
          <div class="commonType">
            <span class="star">* </span>
            <span class="occupation"> 出生日期</span>
          </div>
          <input v-model="queryParams.beneficiaryBirthday" class="occupationType" type="text" name="fname"><br>
          <div class="commonType">
            <span class="star">* </span>
            <span class="occupation"> 国籍</span>
          </div>
          <select class="occupationType" v-model="queryParams.beneficiaryNationality">
            <option v-for="item in fundNationalityTypes" :key="item" :label="item.fundNationalityName"
                    :value="item.fundNationality"></option>
          </select><br>
          <div class="commonType">
            <span class="star">* </span>
            <span class="occupation"> 职业类别</span>
          </div>
          <select class="occupationType" v-model="queryParams.beneficiaryProfCode">
            <option v-for="item in occupationTypes" :key="item" :label="item.ofundProfName"
                    :value="item.ofundProfCode"></option>
          </select>
          <div class="commonType">
            <span class="star">* </span>
            <span class="occupation"> 电话</span>
          </div>
          <input v-model="queryParams.beneficiaryTel" class="occupationType" type="text" name="fname"><br>
          <div class="commonType">
            <span class="star">* </span>
            <span class="occupation"> 邮箱</span>
          </div>
          <input v-model="queryParams.beneficiaryEMail" class="occupationType" type="text" name="fname"><br>
          <div class="commonType">
            <span class="star">* </span>
            <span class="occupation"> 邮编</span>
          </div>
          <input v-model="queryParams.beneficiaryZipcode" class="occupationType" type="text" name="fname"><br>
          <div class="commonType">
            <span class="star">* </span>
            <span class="occupation"> 所在地区</span>
          </div>
          <select class="occupationType">
            <option value="1">请选择</option>
          </select><br>
          <div class="commonType">
            <span class="star">* </span>
            <span class="occupation"> 详细地址</span>
          </div>
          <input v-model="queryParams.beneficiaryAddress" class="occupationType" type="text" name="fname"><br>
        </div>
      </div>
      <div class="commonType">
        <span class="bodyStar">* </span>
        <span class="bodyTitle"> 税收居民身份声明</span>
      </div>
      <select class="bodyType" v-model="queryParams.taxIdentity">
        <option v-for="item in taxIdentityTypes" :key="item" :label="item.taxIdentityName"
                :value="item.taxIdentity"></option>
      </select><br>
      <div class="commonType">
        <span class="bodyStar">* </span>
        <span class="bodyTitle"> 电话</span>
      </div>
      <input v-model="queryParams.homeTel" class="bodyType" type="text" name="fname"><br>
      <div class="commonType">
        <span class="bodyStar">* </span>
        <span class="bodyTitle"> 邮箱</span>
      </div>
      <input v-model="queryParams.email" class="bodyType" type="text" name="fname"><br>
      <div class="commonType">
        <span class="bodyZip"> 邮编</span>
      </div>
      <input v-model="queryParams.zipcode" class="bodyType" type="text" name="fname"><br>
      <div class="next" @click="nextStep">
        <span class="nextFont">下一步</span>
      </div>
      <span class="return">返回</span>
    </form>
  </div>
</template>

<script>
export default {
  name: "OpenAccount2",
  data() {
    return {
      occupationTypes: [
        {ofundProfCode: '1', ofundProfName: '政府部门'},
        {ofundProfCode: '2', ofundProfName: '教科文'},
        {ofundProfCode: '3', ofundProfName: '金融'},
        {ofundProfCode: '8', ofundProfName: '其他'}
      ], // 职业类型字段
      incomeTypes: [
        {income: '1', incomeName: '1万~10万'},
        {income: '2', incomeName: '10万~20万'},
        {income: '3', incomeName: '20万~50万'},
        {income: '4', incomeName: '50万以上'}
      ],
      beneficiaryTypes: [
        {beneficiaryType: '1', beneficiaryName: '本人'},
        {beneficiaryType: '2', beneficiaryName: '他人'},
      ],
      clientGenderTypes: [
        {clientGender: '0', clientGenderName: '男'},
        {clientGender: '1', clientGenderName: '女'},
      ],
      idKindGbTypes: [
        {idKindGb: '0', idKindGbName: '身份证 '},
        {idKindGb: '1', idKindGbName: '外国护照'},
      ],
      fundNationalityTypes: [
        {fundNationality: '156', fundNationalityName: '中国'},
        {fundNationality: '076', fundNationalityName: '巴西'},
        {fundNationality: '036', fundNationalityName: '澳大利亚'},
      ],
      taxIdentityTypes: [
        {taxIdentity: '0', taxIdentityName: '仅为中国税收居民'},
        {taxIdentity: '1', taxIdentityName: '仅为非居民'},
        {taxIdentity: '2', taxIdentityName: '既是中国税收居民又是其他国家（地区）税收居民'},
      ],
      queryParams: {
        clientName: null,
        clientGender: null,
        clientAge: null,
        idKindGb: null,
        idNo: null,
        idEnddate: null,
        fundNationality: null,
        idAddress: null,
        ofundProfCode: null,
        income: null,
        beneficiaryType: null,
        beneficiaryGender: null,
        beneficiary: null,
        beneficiaryIdType: null,
        beneficiaryIdno: null,
        beneficiaryIdValidate: null,
        beneficiaryBirthday: null,
        beneficiaryNationality: null,
        beneficiaryProfCode: null,
        beneficiaryTel: null,
        beneficiaryEMail: null,
        beneficiaryZipcode:null,
        beneficiaryAddress: null,
        taxIdentity: null,
        homeTel: null,
        email: null,
        zipcode: null
      }
    }
  },
  components: {},
  methods: {
    nextStep() {
      alert(JSON.stringify(this.queryParams))
    },
  },
};
</script>

<style scoped>
.OpenAccount2 {
  width: 1200px;
  height: 2643px;
  opacity: 1;
  background: #ffffff;
  box-shadow: 0px 4px 12px 0px #edf1f7;
  margin: auto;
}

.top {
  width: 1200px;
  height: 146px;
  opacity: 1;
  background: #ffffff;
  box-shadow: 0px 4px 12px 0px #edf1f7;
  text-align: center;
  line-height: 146px;
}

.font {
  width: 175px;
  height: 24px;
  opacity: 1;
  font-size: 24px;
  font-weight: 700;
  color: #ce9b63;
  font-family: Microsoft YaHei UI, Microsoft YaHei UI-Bold;
}

.title {
  width: 269px;
  height: 18px;
  opacity: 1;
  font-size: 18px;
  font-family: Microsoft YaHei UI, Microsoft YaHei UI-Bold;
  font-weight: 700;
  text-align: LEFT;
  color: #8691a8;
  line-height: 18px;
  margin-top: 44px;
  margin-left: 190px;
  margin-bottom: 43px;
}

.occupation {
  width: 81px;
  height: 16px;
  opacity: 1;
  font-size: 16px;
  font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
  font-weight: 400;
  text-align: LEFT;
  margin-bottom: 14px;
}

.bodyTitle {
  width: 81px;
  height: 16px;
  opacity: 1;
  font-size: 16px;
  font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
  font-weight: 400;
  text-align: LEFT;
  line-height: 16px;
  margin-bottom: 14px;
}

.bodyZip {
  width: 81px;
  height: 16px;
  opacity: 1;
  font-size: 16px;
  font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
  font-weight: 400;
  text-align: LEFT;
  line-height: 16px;
  margin-left: 191px;
  margin-bottom: 14px;
}

.star {
  width: 81px;
  height: 16px;
  opacity: 1;
  font-size: 16px;
  font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
  font-weight: 400;
  text-align: LEFT;
  color: #c5231c;
  margin-left: 30px;
  margin-bottom: 14px;
}

.bodyStar {
  width: 81px;
  height: 16px;
  opacity: 1;
  font-size: 16px;
  font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
  font-weight: 400;
  text-align: LEFT;
  color: #c5231c;
  line-height: 16px;
  margin-left: 191px;
  margin-bottom: 14px;
}

.bodyType {
  width: 520px;
  height: 48px;
  opacity: 1;
  background: #ffffff;
  border: 1px solid #e0e2e8;
  border-radius: 2px;
  margin-left: 191px;
  margin-bottom: 24px;
}

.occupationType {
  width: 520px;
  height: 48px;
  opacity: 1;
  background: #ffffff;
  border: 1px solid #e0e2e8;
  margin-left: 30px;
  margin-bottom: 24px;
}
.information{
  width: 620px;
  height: 1018px;
  opacity: 1;
  background: #f8f7f7;
  border: 2px solid #e0e2e8;
  border-radius: 4px;
  margin-left: 191px;
  margin-bottom: 14px;
}
.body {
  width: 620px;
  height: 1522px;
  opacity: 1;
  background: #f8f7f7;
  border: 2px solid #e0e2e8;
  border-radius: 4px;
  margin-left: 191px;
  margin-bottom: 14px;
}

.beneficiary {
  width: 162px;
  height: 18px;
  opacity: 1;
  font-size: 18px;
  font-family: Microsoft YaHei UI, Microsoft YaHei UI-Bold;
  font-weight: 700;
  text-align: LEFT;
  color: #1f1f1f;
  line-height: 18px;
  margin-bottom: 14px;
  margin-top: 14px;
  margin-left: 30px;
  margin-bottom: 14px;
}

.next {
  width: 200px;
  height: 48px;
  opacity: 1;
  background: linear-gradient(103deg, #f7d9b7 0%, #eabf96 100%);
  border-radius: 2px;
  text-align: center;
  margin-left: 191px;
  margin-top: 44px;
  display: inline-block;
}

.return {
  width: 32px;
  height: 16px;
  opacity: 1;
  font-size: 16px;
  font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
  font-weight: 400;
  text-align: LEFT;
  color: #ce9b63;
  line-height: 16px;
  margin-left: 28px;
}

.nextFont {
  width: 48px;
  height: 16px;
  opacity: 1;
  font-size: 16px;
  font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
  font-weight: 400;
  text-align: LEFT;
  color: #b88141;
  line-height: 48px;
}
.commonType{
  margin-bottom: 14px;
}
</style>
